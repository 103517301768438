<template>
  <div class="ExamOnline_Edit">

    <el-dialog :visible.sync="paperDialog" title="试卷列表" width="45%" :before-close="beforeClose">
      <el-table :data="paperData" height="400" highlight-current-row>
        <el-table-column
            type="index"
            align="center"
            label="序号"
            width="70"
        ></el-table-column>
        <el-table-column
            v-for="(col, index) in columns"
            :key="index"
            :prop="col.prop"
            :label="col.label"
            :min-width="col.width"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleSelect(scope.row)">
              选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <div class="stu-module-header">
      <div class="stu-module-title">在线考试</div>
      <div>
        <el-button @click="save('editform')" type="primary">保存</el-button>
        <el-button @click="back">返回</el-button>
      </div>
    </div>
    <div class="content" v-loading="loading">
      <div class="message">
        试卷总分：{{ form.totalScore }}分【总分应为{{
          form.totalScore || 100
        }}分】
      </div>
      <el-form :model="form" :rules="rules" ref="editform" label-width="90px">
        <el-form-item label="考试名称" prop="examName">
          <el-input
            v-model="form.examName"
            placeholder="请输入"
            clearable
            show-word-limit
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="answerStartTime">
          <el-date-picker
            v-model="form.answerStartTime"
            type="date"
            placeholder="请选择日期"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd HH:mm:ss"
            :picker-options="pickerStartDate"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="answerEndTime">
          <el-date-picker
            v-model="form.answerEndTime"
            type="date"
            placeholder="请选择日期"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd HH:mm:ss"
            :picker-options="pickerEndDate"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="考试时间" prop="examDuration">
          <el-input-number
            v-model="form.examDuration"
            :min="1"
            :max="99999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="答题要求" prop="answerDesc">
          <el-input
            type="textarea"
            v-model="form.answerDesc"
            placeholder="答题要求"
            show-word-limit
            maxlength="500"
          ></el-input>
        </el-form-item>
        <el-form-item label="出卷次数" prop="rollNum">
          <el-input-number
            :precision="0"
            v-model="form.rollNum"
            :min="0"
            :max="50"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="答题次数" prop="answerNum">
          <el-input-number
            v-model="form.answerNum"
            :precision="0"
            :min="-1"
            :max="99999"
          ></el-input-number>
        </el-form-item>
        <!-- <el-form-item> -->
        <el-table :data="tableData">
          <el-table-column
              label="序号"
              type="index"
              width="60"
              align="center"
          ></el-table-column>
          <el-table-column
              v-for="(col, i) in columns"
              :key="i"
              :prop="col.prop"
              :label="col.label"
              align="center"
          ></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="primary" @click="onSelect(scope.row)">
                选择试卷
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-form-item> -->
        <el-form-item
          label="是否可查看历史试卷"
          prop="historyFlag"
          label-width="160px"
        >
          <el-radio v-model="form.historyFlag" :label="1">是</el-radio>
          <el-radio v-model="form.historyFlag" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="显示得分记录" prop="showFlag" label-width="160px">
          <el-radio v-model="form.showFlag" :label="1">是</el-radio>
          <el-radio v-model="form.showFlag" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="禁止复制粘贴" prop="copyFlag" label-width="160px">
          <el-radio v-model="form.copyFlag" :label="1">是</el-radio>
          <el-radio v-model="form.copyFlag" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item
          label="取消文本框文件按钮"
          prop="buttonFlag"
          label-width="160px"
        >
          <el-radio v-model="form.buttonFlag" :label="1">是</el-radio>
          <el-radio v-model="form.buttonFlag" :label="0">否</el-radio>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  QueryEduCourseExamSetById,
  EduCourseExamSetEdit,
} from "@/libs/api/teacher/online";
export default {
  name: "examOnline_edit",
  data() {
    return {
      paperId: null,
      paperDialog: false,
      paperData: [],
      loading: false,
      columns: [
        { label: "试卷名称", prop: "paperName" },
        { label: "试卷总分", prop: "totalScore" },
        { label: "创建人", prop: "name" },
        { label: "创建时间", prop: "createTime" },
      ],
      form: {
        totalScore: "100.0",
        examName: null,
        answerStartTime: null,
        answerEndTime: null,
        examDuration: 0,
        answerDesc: null,
        rollNum: 0, // 出卷次数
        answerNum: 0, // 答题次数
        list: [],
        historyFlag: null,
        showFlag: null,
        copyFlag: null,
        buttonFlag: null,
      },
      tableData: [],
      pickerStartDate: {
        disabledDate: (time) => {
          if (this.form.answerEndTime) {
            return time.getTime() > this.form.answerEndTime;
          }
        },
      },

      pickerEndDate: {
        disabledDate: (time) => {
          return time.getTime() < this.form.answerStartTime;
        },
      },
      rules: {
        examName: [{ required: true, message: "请输入考试名称" }],
        answerStartTime: [{ required: true, message: "请选择开始时间" }],
        answerEndTime: [{ required: true, message: "请选择结束时间" }],
        examDuration: [{ required: true, message: "请输入考试时长" }],
        answerDesc: [{ required: true, message: "请输入答题要求" }],
        rollNum: [{ required: true, message: "请输入出卷次数" }],
        answerNum: [{ required: true, message: "请输入答题次数" }],
      },
      dialog: false,
      dialogTable: [],
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const id = this.$route.query.id;
      this.loading = true;
      QueryEduCourseExamSetById(id)
        .then((res) => {
          if (res.data) {
            this.form = { ...res.data };
            this.paperId = res.data.paperId;
            this.initeduTestPaper(res.data.paperId,false);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initeduTestPaper(id,bool){
      let formData = new FormData();
      formData.append("courseId",this.course.id)
      formData.append("id",id)
      this.$axios_supermallData.post("/hnjxjy-core/eduTestPaper/list",formData)
      .then((resp) => {
        if (resp.data.code === 2000){
          this.tableData = resp.data.data;
        }else {

        }
        this.loading = false;
        if (bool){
          this.loadingView.close();
        }
      })
      .catch((err) => {
        this.loading = false;
        if (bool){
          this.loadingView.close();
        }
      });
    },
    save() {
      this.openLoadingView();
      if (this.paperId == null){
        return this.$message.error("请选择试卷")
      }
      let params = {
        id: this.form.id, //考试id
        schoolId: this.form.schoolId, //院校id
        courseId: this.course.id, //课程id
        batchId: this.form.batchId, //批次id
        examName: this.form.examName, //考试名称
        producerId: this.form.producerId, //老师id
        paperId: this.paperId, //试卷id
        examDuration: this.form.examDuration, // 考试时间
        answerStartTime: this.form.answerStartTime, //考试开始时间
        answerEndTime: this.form.answerEndTime, //考试结束时间
        answerDesc: this.form.answerDesc, //答题要求
        rollNum: this.form.rollNum, //出卷次数
        answerNum: this.form.answerNum, //答题次数
        historyFlag: this.form.historyFlag, //显示可查看历史试卷 0否 1是
        showFlag: this.form.showFlag, //显示记录得分 0否 1是
        copyFlag: this.form.copyFlag, //显示记录得分 0否 1是
        buttonFlag: this.form.buttonFlag //取消文本框文件按钮 0否 1是
      }
      this.openLoadingView();
      this.$axios.post("/hnjxjy-core/eduCourseExamSet/edit",params)
      .then((res) => {
        if (res.data.code === 2000){
          this.$message.success(res.data.message);
          this.init();
          this.loadingView.close();
        }else {
          this.$message.error(res.data.message);
          this.loadingView.close();
        }
      })
      .catch((err) => {
        this.loadingView.close();
      });
    },
    back() {
      this.$router.push("/teacher/exam");
    },
    eduTestPaperList(){
      let formData = new FormData();
      formData.append("courseId",this.course.id)
      this.$axios_supermallData.post("/hnjxjy-core/eduTestPaper/list",formData)
          .then((resp) => {
            if (resp.data.code === 2000){
              this.paperData = resp.data.data;
            }else {

            }
            this.loadingView.close();
          })
          .catch((err) => {
            this.loadingView.close();
          });
    },
    onSelect(row) {
      this.paperDialog = true;
      setTimeout(()=>{
        this.openLoadingView();
      },100)
      setTimeout(()=>{
        this.eduTestPaperList();
      },500)
    },

    handleSelect(row){
      this.paperId = row.id;
      setTimeout(()=>{
        this.openLoadingView();
      },100)
      setTimeout(()=>{
        this.initeduTestPaper(row.id,true);
      },500)
      this.paperDialog = false;
      this.paperData = [];
    },
    beforeClose(done){
      done();
      this.paperData = [];
    }
  },
};
</script>

<style scoped lang="less">
.ExamOnline_Edit {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 20px;
    width: 60%;
    min-width: 400px;
    margin: 0 auto;
    .message {
      margin-bottom: 20px;
      background: #fcfcfc;
      padding: 20px;
    }
  }
}
</style>
<style lang="less">
.ExamOnline_Edit {
  .content {
    .el-form {
      .el-form-item__label {
        font-weight: bold;
      }
    }
  }
}
</style>
